.roulette-wrapper {
  width: 150px;
  height: 150px;

  > div {
    height: 100%;
    width: 100%;
    max-height: unset;
    max-width: unset;
  }
}
