.ContactPage {
  padding: 2rem;
}

.ContactPage h1 {
  margin-bottom: 1rem;
}

.ContactPage form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContactPage form div {
  margin-bottom: 1rem;
  width: 100%;
}

.ContactPage form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.ContactPage form input,
.ContactPage form textarea,
.ContactPage form select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.ContactPage form button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  align-self: flex-start;
}

.ContactPage form button:hover {
  background-color: #0056b3;
}

.contact-info {
  margin-top: 2rem;
}

.contact-info a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: black;
}

.contact-info a img {
  margin-bottom: 0.5rem;
}

.contact-info a span {
  font-weight: bold;
}
.new-contacts-section {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.new-contacts-section h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.new-contacts-section ul {
  list-style-type: none;
  padding: 0;
}

.new-contacts-section li {
  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.new-contacts-section li p {
  margin: 5px 0;
}

.new-contacts-section li p:first-child {
  font-weight: bold;
}

.new-contacts-section li p:last-child {
  font-size: 12px;
  color: #999;
}
